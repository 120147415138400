<template>
  <c-tab
    ref="complianceTab"
    type="horizon"
    :dense="true"
    :inlineLabel="true"
    :tabItems.sync="tabItems"
    :height="tabHeight"
    v-model="tab"
  >
    <template v-slot:default="tab">
      <component
        :is="tab.component"
        :popupParam="popupParam"
        :evalTable.sync="evalTable"
        :imprTabInfo.sync="imprTabInfo"
        :isOld.sync="isOld"
        :inputEditable.sync="inputEditable"
        :research.sync="research"
        :contentHeight.sync="contentHeight"
        @closePopup="closePopup"
        @setRegInfo="setRegInfo"
      />
    </template>
  </c-tab>
</template>
<script>
/* eslint-disable no-unused-vars */
import selectConfig from '@/js/selectConfig';
export default {
  name: 'compliance-eval-table-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          saiComplianceEvalTableId: '',
          isApprContent: true,
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'base',
      tabItems: [
        //준수평가 정보
        { name: 'base', icon: 'foundation', label: 'LBL0010055', component: () => import(`${'@/pages/sai/law/evalTable/complianceEvalTableInfo.vue'}`) },
        //감소대책 계획 및 실행
        { name: 'impr', icon: 'account_tree', label: 'LBL0010056', component: () => import(`${'@/pages/common/ibm/imprTab.vue'}`) },
      ],
      evalTable: {
        saiComplianceEvalTableId: '',  // 법규등록부 및 준수평가표 일련번호
        requirementName: '',  // 법규/기타 요건명
        complianceEvalTypeCd: null,  // 평가구분
        lawRegisterItemTypeCd: null,  // 환경안전기타구분 업무구분
        lawRegisterItemTypeName: '',
        quarterCd: null,  // 분기
        quarterName: '',
        remark: '',  // 비고
        evalYear: '',  // 평가년도
        reviewUserId: '',  // 검토자ID
        approvalUserId: '',  // 승인자ID
        plantCd: '',  // 사업장
        targetDeptCd: '',  // 평가부서
        targetDeptName: '',  // 평가부서
        saiComplianceEvalTableStepCd: '',  // 진행상태(작성중)
        startDt: '',  // 평가일자
        endDt: '',  // 평가종료일
        compliances: [],
        deleteCompliances: [],
        compliancesTypes: [],
      },
      research: {
        impr: '',
        item: '',
      },
      editable: true,
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 45) + 'px';
    },
    disabled() {
      return this.evalTable.saiComplianceEvalTableStepCd === 'CETS000005';
    },
    isOld() {
      return Boolean(this.popupParam.saiComplianceEvalTableId);
    },
    isCur() {
      return this.popupParam.saiComplianceEvalTableId ? 
        this.popupParam.saiComplianceEvalTableId === this.evalTable.saiComplianceEvalTableId
        : true
    },
    inputEditable() {
      return this.editable 
        && this.isCur
        && !this.disabled
    },
    imprTabInfo() {
      return {
        key: this.popupParam.saiComplianceEvalTableId, // 
        ibmTaskTypeCd: 'ITT0000160',
        ibmTaskUnderTypeCd: 'ITTU000165',
        requestContents: this.evalTable.requirementName,
        disabled: !this.inputEditable,
        isOld: this.isOld,
        gridItem: {
          listUrl: selectConfig.sai.evalTable.complianceEvalTable.results.url,
          param: {
            saiComplianceEvalTableId: this.popupParam.saiComplianceEvalTableId,
            imprFlag: true,
          },
          title: 'LBL0010057', //준수평가 별 개선사항 목록
          merge: [
            { index: 0, colName: 'lawId' },
            { index: 1, colName: 'saiLawRegisterMasterId' },
          ],
          columns: [
            {
              name: 'lawName',
              field: 'lawName',
              //법령명
              label: 'LBL0010058',
              align: 'center',
              style: 'width:90px',
              sortable: false,
              fix: true,
            },
            {
              name: 'gubunName',
              field: 'gubunName',
              //구분
              label: 'LBL0010059',
              align: 'left',
              style: 'width:100px',
              sortable: false,
              fix: true,
            },
            {
              name: 'itemName',
              field: 'itemName',
              label: 'LBLITEMS',
              align: 'left',
              style: 'width:120px',
              sortable: false,
              fix: true,
            },
            {
              name: 'relevantClause',
              field: 'relevantClause',
              //관련조항
              label: 'LBL0010060',
              align: 'left',
              style: 'width:90px',
              sortable: false,
              fix: true,
            },
            {
              name: 'requirements',
              field: 'requirements',
              //요구사항
              label: 'LBL0010061',
              align: 'left',
              style: 'width:350px',
              sortable: false,
              type: 'html'
            },
            {
              name: 'applyConstruction',
              field: 'applyConstruction',
              //적용사항
              label: 'LBL0010062',
              align: 'left',
              style: 'width:200px',
              sortable: false,
              type: 'html'
            },
            {
              name: 'target',
              field: 'target',
              //적용대상
              label: 'LBL0010063',
              align: 'left',
              style: 'width:80px',
              sortable: false,
              type: 'html'
            },
            {
              name: 'relatedDocument',
              field: 'relatedDocument',
              //관련문서<br/>(기록)
              label: 'LBL0010064',
              align: 'left',
              style: 'width:80px',
              sortable: false,
              type: 'html'
            },
            {
              name: 'saiComplianceResultName',
              field: 'saiComplianceResultName',
              //준수평가결과
              label: 'LBL0010065',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'remark',
              field: 'remark',
              //비고
              label: 'LBLREMARK',
              align: 'left',
              style: 'width:150px',
              sortable: false,
            },
          ],
          research: this.research,
        },
        gridOther: {
          title: 'LBL0010066',    //항목외 개선 목록
        },
      }
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
    closePopup() {
      this.$emit('closePopup')
    },
    setRegInfo(data) {
      this.$emit('setRegInfo', data)
    }
  }
};
</script>